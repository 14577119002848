import React from "react";
// Data
import { InfoSectionData } from "../../data/InfoSection.data";
// Styles
import {
  MobilbarContainer,
  MobilbarMenu,
  MobilItemWrapper,
  MobilbarLink,
  MobilIcon,
} from "./Mobilbar.styles";
// Types
type Props = {
  mobilMenuState: boolean;
  toggleMobileMenuState: () => void;
};
const Mobilbar: React.FC<Props> = ({
  mobilMenuState,
  toggleMobileMenuState,
}) => {
  return (
    <MobilbarContainer
      onClick={toggleMobileMenuState}
      state={mobilMenuState ? "open" : ""}
    >
      <MobilIcon onClick={toggleMobileMenuState} />

      <MobilbarMenu>
        {InfoSectionData.map((obj) => (
          <MobilItemWrapper key={obj.id + "_mob"}>
            <MobilbarLink
              onClick={toggleMobileMenuState}
              to={obj.id}
              smooth={true}
              duration={800}
              spy={true}
              offset={-80}
            >
              {obj.title}
            </MobilbarLink>
          </MobilItemWrapper>
        ))}
      </MobilbarMenu>
    </MobilbarContainer>
  );
};

export default Mobilbar;
