import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
// Data
import { InfoSectionData } from "../data/InfoSection.data";
// Componentns
import Navbar from "../components/Navbar";
import Mobilbar from "../components/Mobilbar";
import Hero from "../components/HeroSection";
import InfoSection from "../components/InfoSection";
import Footer from "../components/footer";

const Header: React.FC = () => {
  const [mobilMenuState, setMobilMenuState] = useState(false);
  const toggleMobileMenuState = () => {
    setMobilMenuState(!mobilMenuState);
  };

  const isMobile = useMediaQuery({ query: `(min-width: 760px)` });
  useEffect(() => {
    if (isMobile) {
      setMobilMenuState(false);
    }
  }, [isMobile, setMobilMenuState]);
  return (
    <>
      <Navbar
        mobilMenuState={mobilMenuState}
        toggleMobileMenuState={toggleMobileMenuState}
      />
      <Mobilbar
        mobilMenuState={mobilMenuState}
        toggleMobileMenuState={toggleMobileMenuState}
      />
      <Hero />
      <>
        {InfoSectionData.map((infoObj) => (
          <InfoSection key={infoObj.id} {...infoObj} />
        ))}
      </>
      <Footer />
    </>
  );
};

export default Header;
