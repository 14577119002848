import styled from "styled-components";

export const FooterContainer = styled.footer`
  background: var(--background);
  color: white;
  padding-bottom: 200px;
  padding-top: 200px;
  text-align: center;
`;

export const SocialMediaLinks = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;

  a {
    margin: 0 10px;
    img {
      width: 30px; /* Adjust the size as needed */
      height: 30px; /* Adjust the size as needed */
    }
  }
`;
