import omOssImage from "../images/pod_room.jpg";
import podcastImage from "../images/pod.png";
import musikkImage from "../images/logo_color.svg";
// Types
export type InfoSectionDataType = {
  id: string;
  topText: string;
  title: string;
  subTitle: string;
  startLeft: boolean;
  darkTheme: boolean;
  type: string;
  mediaUrl: string;
  linkUrl: string;
  linkText: string;
};

// Data
export const InfoSectionData = [
  {
    id: "om-oss",
    topText: "Velkommen til Utlagt Sjarm",
    title: "Om oss",
    subTitle:
      "Podcasten og bandet drevet av to gode venner, Emil og David! Dykk ned i vårt univers av sjarm, tarm, latter og musikk, der vi utforsker alt fra livets små øyeblikk til de store mysteriene.",
    startLeft: false,
    darkTheme: true,
    type: "image",
    mediaUrl: omOssImage,
    linkUrl: "",
    linkText: "",
  },
  {
    id: "podcast",
    topText: "Utlagt Sjarm Podcast 🎙",
    title: "Podcast",
    subTitle:
      "Vil du bli med på en eventyrlig reise med to gode venner som utforsker tull? Besøk oss på YouTube!",
    startLeft: true,
    darkTheme: false,
    type: "image",
    mediaUrl: podcastImage,
    linkUrl: "https://www.youtube.com/channel/UCorT8mZyZrpMIPF9SCshjAw",
    linkText: "Se og lytt her!",
  },
  {
    id: "musikk",
    topText: "Utlagt Sjarm 🎸",
    title: "Musikk",
    subTitle:
      "Liker du Black Sabbath, uteliggere og brøling? Finn oss på Spotify! ",
    startLeft: false,
    darkTheme: true,
    type: "image",
    mediaUrl: musikkImage,
    linkUrl: "https://open.spotify.com/artist/3EnhzJmE3Du4QciyUQlO50?si=-XHQf95IRniVifsAtcvz-A",
    linkText: "Lytt her!",
  },
];
