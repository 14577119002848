import React, { useState, useEffect } from "react";
import { animateScroll } from "react-scroll";
// Data
import logoImg from "../../images/logo.svg";
import { InfoSectionData } from "../../data/InfoSection.data";
// Styles
import {
  Nav,
  Wrapper,
  NavMenu,
  NavItem,
  NavLinks,
  Content,
  LogoLink,
  Logo,
  MobilIcon,
} from "./Navbar.styles";
// Types
type Props = {
  mobilMenuState: boolean;
  toggleMobileMenuState: () => void;
};
const Navbar: React.FC<Props> = ({ mobilMenuState, toggleMobileMenuState }) => {
  const [scrollNav, setScrollNav] = useState(false);
  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);
  const scrollToHome = () => {
    animateScroll.scrollToTop();
  };
  return (
    <Nav background={scrollNav ? "var(--background)" : "transparent"}>
      <Content>
        <Wrapper>
          <LogoLink to="/" onClick={scrollToHome}>
            <Logo src={logoImg} />
          </LogoLink>
        </Wrapper>
        <Wrapper>
          <NavMenu>
            {InfoSectionData.map((obj) => (
              <NavItem key={obj.id + "_nav"}>
                <NavLinks
                  to={obj.id}
                  smooth={true}
                  duration={800}
                  spy={true}
                  offset={-80}
                >
                  {obj.title}
                </NavLinks>
              </NavItem>
            ))}
          </NavMenu>
          <MobilIcon onClick={toggleMobileMenuState} />
        </Wrapper>
      </Content>
    </Nav>
  );
};

export default Navbar;
