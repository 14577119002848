import styled from "styled-components";
// Types

type Props = {
  state: string;
};
export const Container = styled.div<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(Props) =>
    Props.state === "true" ? "var(--black)" : "var(--background)"};
`;
export const Wrapper = styled.div`
  display: grid;
  z-index: 1;
  height: 820px;
  width: 100%;
  max-width: var(--maxWidth);
  padding: 20px;
`;
export const InfoRow = styled.div<Props>`
  width: 100%;
  height: 100%;

  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  grid-template-areas: ${(Props) =>
    Props.state === "true" ? `'col2 col1'` : `'col1 col2'`};
  @media screen and (max-width: 768px) {
    grid-template-areas: ${(Props) =>
    Props.state === "true" ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`};
  }
`;
export const Column1 = styled.div`
  grid-area: col1;
  height: 100%;
  width: 100%;
`;
export const Column2 = styled.div`
  grid-area: col2;
  width: 100%;
  height: 100%;
`;
export const TextWrapper = styled.div<Props>`
  display: flex;
  justify-content: center;
  align-items: ${(Props) =>
    Props.state === "true" ? "flex-end" : "flex-start"};
  text-align: ${(Props) => (Props.state === "true" ? "end" : "start")};
  flex-direction: column;
  height: 100%;
  width: 100%;
`;
export const TopLine = styled.p<Props>`
  color: ${(Props) =>
    Props.state === "true" ? "var(--color1)" : "var(--color1)"};
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
`;
export const InfoButton = styled.button<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  border: 1px solid;
  border-radius: 4px;
  padding: 8px 22px;

  border-color: ${(Props) =>
    Props.state === "true" ? "var(--color1)" : "var(--color1)"};
  background: ${(Props) =>
    Props.state === "true" ? "var(--background)" : "var(--background)"};
  color: ${(Props) =>
    Props.state === "true" ? "var(--color1)" : "var(--color1)"};

  white-space: nowrap;
  outline: none;
  cursor: pointer;

  transition: all 0.3s ease-in-out;
  &:hover {
    border-radius: 10px;
  }
`;
export const Heading = styled.h1<Props>`
  margin-bottom: 24px;
  color: ${(Props) =>
    Props.state === "true" ? "var(--white)" : "var(--white)"};
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;
export const Subtitle = styled.p<Props>`
  margin-bottom: 34px;

  color: ${(Props) =>
    Props.state === "true" ? "var(--color2)" : "var(--color2)"};
  font-size: 18px;
  line-height: 24px;
`;
export const VideoWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
export const Image = styled.img`
  width: 100%;
  border-radius: 4px;
  -o-object-fit: cover;
  object-fit: cover;
`;
