import youtube from '../../images/youtube.png';
import x from '../../images/twitter.png';
import twitch from '../../images/twitch.png';
import tiktok from '../../images/tiktok.png';
import instagram from '../../images/instagram.png';
import spotify from '../../images/spotify.png';

import { FooterContainer, SocialMediaLinks } from './Footer.styles';


const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <FooterContainer>
      <SocialMediaLinks>
        <a href="https://open.spotify.com/artist/3EnhzJmE3Du4QciyUQlO50?si=-XHQf95IRniVifsAtcvz-A" target="_blank" rel="noopener noreferrer">
          <img src={spotify} alt="Spotify" />
        </a>
        <a href="https://www.youtube.com/channel/UCorT8mZyZrpMIPF9SCshjAw" target="_blank" rel="noopener noreferrer">
          <img src={youtube} alt="YouTube" />
        </a>
        <a href="https://www.tiktok.com/@utlagtsjarm" target="_blank" rel="noopener noreferrer">
          <img src={tiktok} alt="TikTok" />
        </a>
        <a href="https://twitter.com/UtSjarm" target="_blank" rel="noopener noreferrer">
          <img src={x} alt="Twitter" />
        </a>
        <a href="https://www.instagram.com/utlagtsjarm" target="_blank" rel="noopener noreferrer">
          <img src={instagram} alt="Instagram" />
        </a>
        <a href="https://www.twitch.tv/utlagtsjarm" target="_blank" rel="noopener noreferrer">
          <img src={twitch} alt="Twitch" />
        </a>
      </SocialMediaLinks>
      <p>&copy; {currentYear} Utlagt Sjarm. All rights reserved.</p>
    </FooterContainer>
  );
};

export default Footer;
