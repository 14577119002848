import React, { useState, useEffect } from "react";
// Local files
import Video from "../../videos/logo.mp4";
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
} from "./HeroSection.styles";
const Hero: React.FC = () => {
  const [scroll, setScroll] = useState(false);
  const changeScroll = () => {
    if (window.scrollY >= 80) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", changeScroll);
  }, []);

  return (
    <HeroContainer id="home">
      <HeroBg>
        <VideoBg autoPlay loop muted src={Video} typeof="video/mp4" />
      </HeroBg>
      <HeroContent scroll={scroll ? "true" : ""}>
      </HeroContent>
    </HeroContainer>
  );
};

export default Hero;
