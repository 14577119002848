import React from "react";
import ReactPlayer from "react-player";

// Styles
import {
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  Container,
  Wrapper,
  InfoRow,
  Column1,
  Column2,
  VideoWrapper,
  InfoButton,
  Image,
} from "./InfoSection.styles";
// Types
import { InfoSectionDataType } from "../../data/InfoSection.data";

const InfoSectionGrid: React.FC<InfoSectionDataType> = (props) => {
  return (
    <>
      <Container state={props.darkTheme ? "true" : "false"} id={props.id}>
        <Wrapper>
          <InfoRow state={props.startLeft ? "true" : "false"}>
            <Column1>
              <TextWrapper state={props.startLeft ? "true" : "false"}>
                <TopLine state={props.darkTheme ? "true" : "false"}>
                  {props.topText}
                </TopLine>
                <Heading state={props.darkTheme ? "true" : "false"}>
                  {props.title}
                </Heading>
                <Subtitle state={props.darkTheme ? "true" : "false"}>
                  {props.subTitle}
                </Subtitle>
                {props.linkUrl && (
                  <InfoButton
                    state={props.darkTheme ? "true" : "false"}
                    onClick={(e) => {
                      e.preventDefault();
                      window.location.href = props.linkUrl;
                    }}
                  >
                    {props.linkText}
                  </InfoButton>
                )}
              </TextWrapper>
            </Column1>
            <Column2>
              {props.type === "video" ? (
                <VideoWrapper>
                  <ReactPlayer width="100%" controls={true} url={props.mediaUrl} />
                </VideoWrapper>
              ) : (
                <VideoWrapper>
                  <Image src={props.mediaUrl} />
                </VideoWrapper>
              )}
            </Column2>
          </InfoRow>
        </Wrapper>
      </Container>
    </>
  );
};

export default InfoSectionGrid;
