import styled from "styled-components";
// Types
type ScrollProps = {
  scroll: string;
};
export const HeroContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  z-index: 1;
  :before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.9) 0%,
      transparent 100%
    );
    z-index: 2;
  }
`;
export const HeroBg = styled.div`
  z-index: -1;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;
export const VideoBg = styled.video`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
`;
export const HeroContent = styled.div<ScrollProps>`
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: ${(ScrollProps) =>
    ScrollProps.scroll === "true" ? `70px` : `60px`};
  gap: ${(ScrollProps) => (ScrollProps.scroll === "true" ? `20px` : `5px`)};
  text-align: center;
  filter: drop-shadow(
    0 0 ${(ScrollProps) => (ScrollProps.scroll === "true" ? `6px` : `15px`)}
      var(--white)
  );
  user-select: none;
  overflow: none;
  letter-spacing: ${(ScrollProps) =>
    ScrollProps.scroll === "true" ? `25px` : `10px`};
  transition: ease-in-out 1s;
`;

