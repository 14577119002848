import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { Link as LinkS } from "react-scroll";
import { FaBars } from "react-icons/fa";

// Types
type NavProps = {
  background: string;
};
export const Nav = styled.nav<NavProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  background: ${(NavProps) => NavProps.background};
  width: 100%;
  height: 80px;
  margin-top: -80px;
  position: sticky;
  top: 0;
  z-index: 10;
  transition: 0.6s ease-in-out;
  @media screen and (max-width: 960px) {
    transition: 0.6s ease-in-out;
  }
`;
export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: var(--maxWidth);
  padding: 20px;
  user-select: none;
`;
export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const LogoLink = styled(LinkR)`
  display: flex;
  text-decoration: none;
  cursor: pointer;
`;
export const Logo = styled.img`
  margin-right: 5px;
  margin-left: 5px;
  transition: 0.3s ease-in-out;
  opacity: 1.0;
  height: 4.1rem;
  &:hover {
    opacity: 0.8;
    transform: scale(1.1);
  }
`;
export const MobilIcon = styled(FaBars)`
  display: none;
  @media screen and (max-width: 768px) {
    color: var(--white);
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;
export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;
export const NavItem = styled.li`
  height: 40px;
`;
export const NavLinks = styled(LinkS)`
  color: var(--white);
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  margin-left: 4px;
  margin-right: 4px;
  height: 100%;
  cursor: pointer;
  border-radius: 2px;
  border: 1px solid transparent;
  font-size: var(--fontSmall);
  background: transparent;
  transition: 0.6s ease-in-out;

  &:hover {
    color: var(--color1);
    background: rgba(100%, 100%, 100%, 0.1);
    transition: 0.1s ease-in-out;
  }
  &.active {
    transition: 0.5s ease-in-out;
    color: var(--color1);
    background: rgba(100%, 100%, 100%, 0.075);
    border: 1px solid rgba(100%, 100%, 100%, 0.15);
  }
`;
