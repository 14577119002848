import { createGlobalStyle } from "styled-components";
export const GlobalStyle = createGlobalStyle`
:root {
    --maxWidth: 1280px;
    --background: #0D1117;
    --color1: #58A6FF;
    --color2: #C9D1D9;
    --black: #000000;
    --white: #ffffff;

    --fontSmall: 1.1rem;
    --fontMed: 1.3rem;
    --fontBig: 1.5rem;
}
* {
    box-sizing: border-box;
    font-family: "Inter", sans-serif;
}
body {
    margin: 0;
    padding: 0;
    h1 {
        font-size: 2rem;
        font-weight: 600;
        color: var(--colorOne);
    }
    h2 {
        font-size: 1.6rem;
        font-weight: 600;
        color: var(--colorOne);
    }
    h3 {
        font-size: 1.1rem;
        font-weight: 600;
        color: var(--colorOne);
    }
    p {
        font-size: 1rem;
        color: var(--colorOne);
    }
}
`;
