import styled from "styled-components";
import { Link as LinkS } from "react-scroll";
import { FaTimes } from "react-icons/fa";
type ContainerPorps = {
  state: string;
};
export const MobilbarContainer = styled.aside<ContainerPorps>`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: var(--black);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  top: 0;
  left: 0;
  padding: 80px;
  transition: 0.3s ease-in-out;
  opacity: ${(ContainerPorps) =>
    ContainerPorps.state === "open" ? "100%" : "0%"};
  top: ${(ContainerPorps) => (ContainerPorps.state === "open" ? "0" : "-100%")};
`;

export const MobilIcon = styled(FaTimes)`
  display: none;
  @media screen and (max-width: 768px) {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: var(--white);
  }
`;

export const MobilbarMenu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: 60px;
  padding: 20px;
`;
export const MobilItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const MobilbarLink = styled(LinkS)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--fontMed);
  text-decoration: none;
  list-style: none;
  transition: 0.3s ease-in-out;
  text-decoration: none;
  color: var(--white);
  cursor: pointer;

  &:hover {
    color: var(--color1);
    transition: 0.3s ease-in-out;
  }
`;
